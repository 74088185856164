import React, { createContext, useContext, useState, useEffect } from "react";
import {UserObject} from "./contracts/Company";

const UserContext = createContext({});

// @ts-ignore
export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(undefined);


    // const [profileInfo] = React.useState()) as UserObject)

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserInformation = () => useContext(UserContext);
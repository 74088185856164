import React, {FC, useContext} from 'react';
import {useTheme} from "@mui/material";
import styles from './SideMenu.module.scss';
import SideMenuItem from "../SideMenuItem/SideMenuItem";
import {UserObject} from "../../contracts/Company";
import { useUserInformation } from '../../Contexts';
import {CircularProgress} from "@material-ui/core";

interface SideMenuProps {
}

const SideMenu: FC<SideMenuProps> = () => {
    const user: any = useUserInformation();
    // console.log(user);

    // const [profileInfo] = React.useState(JSON.parse(localStorage.getItem('user') || JSON.stringify({
    //     "email": 'demo@gatortech.nl',
    //     "kvkNumber": '12345678',
    //     "token": 'adfsa98ds7f98asd7',
    //     "company": undefined
    // })) as UserObject)
    const theme = useTheme();
    // const profileAddress = profileInfo.company._EmbeddedCompanyInfo.headquarters.address[0];

    return <>
        <div className={styles.sideMenu}>
            {user.user !== undefined ?
                <div className={styles.profileInfo}>
                    <span className={styles.circleShortAccountName} style={{backgroundColor: theme.palette.primary.light}}>
                        {user.user.email.slice(0, 2)}
                    </span>
                    <span className={styles.profileCompanyName}>{user.user.name}</span>
                    <span className={styles.profileCompanyAddress}>{user.user.street}</span>
                    <span className={styles.profileCompanyAddress}>{user.user.city}</span>
                    <span className={styles.profileCompanyAddress}>&nbsp;</span>
                    <span className={styles.profileCompanyAddress}>{user.user.email}</span>
                    <div className={styles.spacer}></div>
                </div>
                :
                <div className={styles.profileInfo}>
                    {/*<CircularProgress />*/}
                </div>
            }
            <div className={styles.menu}>
                <SideMenuItem icon='dashboard_outline' linkToUrl='/dashboard' itemText='Dashboard'></SideMenuItem>
                <SideMenuItem icon='upload_outline' linkToUrl='/' itemText='Upload'></SideMenuItem>
                <SideMenuItem icon='bar_chart_outline' linkToUrl='/inzichten' itemText='Inzichten' itemTextIcon='construction'></SideMenuItem>
                <SideMenuItem icon='multiline_chart' linkToUrl='/trends' itemText='Trends' itemTextIcon='construction'></SideMenuItem>
                <SideMenuItem icon='compare_outline' linkToUrl='/vergelijken' itemText='Vergelijkingen' itemTextIcon='construction'></SideMenuItem>
                <SideMenuItem icon='person_outline' linkToUrl='/profiel' itemText='Mijn profiel' itemTextIcon='construction'></SideMenuItem>
            </div>
        </div>
    </>
};

export default SideMenu;

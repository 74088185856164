import React, {FC, Fragment, useEffect, useState} from 'react';
import styles from './Dashboard.module.scss';
import Header from "../Header/Header";
import {
    Card,
    CardContent, CardHeader, Chip,
    Icon,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {DropzoneArea} from "material-ui-dropzone";
import FileService from "../../services/User/File.service";
import AuthenticationService from "../../services/User/Authentication.service";
import insightService from "../../services/Insights/Insight.service";
import {UserObject} from "../../contracts/Company";
import {CircularProgress, Grid, LinearProgress} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import InsightsTable from "../Insights/InsightsTable";
import {useDashboardStats} from "../../Hooks/useDashboardStats";
import {Doughnut, Line, Pie} from "react-chartjs-2";
import { useLocation } from "react-router-dom"
import {TableRows} from "@mui/icons-material";

interface OverviewTableProps {
    overviewData: any
}

const renderAccounts = (accounts: any): any => {

    let counter = 0;

    return Object.entries(accounts).map((item: any) => {

        const [key, value] =  item;

        let start = new Date(Date.parse(value.start));
        let stop = new Date(Date.parse(value.end));

        var startDate = start.getUTCDate() + '-' + (start.getUTCMonth() + 1) + '-' + start.getUTCFullYear();
        var stopDate = stop.getUTCDate() + '-' + (stop.getUTCMonth() + 1) + '-' + stop.getUTCFullYear();

        counter++;

        return (
            <Fragment key={counter}>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>Account {counter}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                     <TableRow>
                         <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>IBAN</TableCell>
                         <TableCell>{key}</TableCell>
                     </TableRow>
                     <TableRow>
                         <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>Spandatum</TableCell>
                         <TableCell>{startDate} t/m {stopDate}</TableCell>
                     </TableRow>
            </Fragment>
        )
    });
}

const renderCategoryTotals = (categoryTotals: any): any => {

    let counter = 0;

    return Object.entries(categoryTotals).map((item: any) => {

        const [key, value] =  item;

        // console.log(key);
        // console.log(value);

        counter++;

        let saldo = value.in + value.out;

        const formatter = new Intl.NumberFormat('nl-NL', {
            // style: 'number',
            // currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return (
            <TableRow key={counter}>
                <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>{key}</TableCell>
                <TableCell  align={"right"} style={{ color: '#4caf50', whiteSpace: 'nowrap'  }}>
                    {value.in !== 0 ? <div style={{float: 'left'}}>&euro;&nbsp;</div> : ''}
                    {value.in !== 0 ? formatter.format(value.in) : '-'}
                </TableCell>
                <TableCell  align={"right"} style={{ color: '#f44336' , whiteSpace: 'nowrap' }}>
                    {value.out !== 0 ? <div style={{float: 'left'}}>&euro;&nbsp;</div> : ''}
                    {value.out !== 0 ? formatter.format(value.out) : '-'}
                </TableCell>
                {/*<TableCell align={"right"} style={{ color: '#2196F3', whiteSpace: 'nowrap' }}>*/}
                <TableCell align={"right"} style={{ color: (saldo > 0 ? '#4caf50' : '#f44336') , whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                    {saldo !== 0 ? <div style={{float: 'left'}}>&euro;&nbsp;</div> : ''}
                    {saldo !== 0 ? formatter.format(saldo) : '-'}
                </TableCell>
            </TableRow>
        )
    });
}
// const renderCategoryTabs = (transactionsByCategory: any): any => {
//
//     let counter = 0;
//
//     console.log(transactionsByCategory);
//
//     return ['loan', 'tax', 'debt-legal', 'rent', 'seizure', 'storno'].map((category: any) => {
//
//         if(transactionsByCategory[category] !== undefined && transactionsByCategory[category].length > 0) {
//         }
//         return (<Tab label="{ category }" />);
//
//     });
//
//     return Object.entries(transactionsByCategory).map((item: any) => {
//
//         const [key, value] =  item;
//
//         console.log(key);
//         console.log(value);
//
//         counter++;
//
//         const formatter = new Intl.NumberFormat('nl-NL', {
//             style: 'currency',
//             currency: 'EUR',
//
//             // These options are needed to round to whole numbers if that's what you want.
//             //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//             //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
//         });
//         return (
//             <TableRow>
//                 <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>{key}</TableCell>
//                 <TableCell style={{ color: '#4caf50' }}>{formatter.format(value.in)}</TableCell>
//                 <TableCell style={{ color: '#f44336' }}>{formatter.format(value.out)}</TableCell>
//             </TableRow>
//         )
//     });
// }

const OverviewTable: FC<OverviewTableProps> = (props) => {

    let overviewData = props.overviewData;

    // console.log(overviewData);

    let accountData = overviewData.accountData;
    let accounts = accountData.accountsRevised;
    let categoryTotals = overviewData.categoryTotals;
    // console.log(accounts);

    const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <Card sx={{
                    width: 'calc((100%) - 30px)',
                    padding: '15px'
                }}>
                    <CardHeader titleTypographyProps={{
                        fontWeight: 800,
                    }} title={"Overzicht van uw gegevens"} style={{color: '#013f63', fontWeight: 'bold'}} />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Table size="small">
                                        <TableBody>
                                            {renderAccounts(accounts)}
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>Totaalbedrag In</TableCell>
                                                <TableCell>{formatter.format(accountData.totalIn)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>Totaalbedrag Uit</TableCell>
                                                <TableCell>{formatter.format(accountData.totalOut)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>Totaalbedrag In excl leningen</TableCell>
                                                <TableCell>{formatter.format(accountData.totalInExclLoans)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>Totaalbedrag uit excl leningen</TableCell>
                                                <TableCell>{formatter.format(accountData.totalOutExclLoans)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>Saldo alle rekeningen</TableCell>
                                                <TableCell>{formatter.format(accountData.balance)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={6}>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Categorie</TableCell>
                                                <TableCell>Bij</TableCell>
                                                <TableCell>Af</TableCell>
                                                <TableCell>Saldo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {renderCategoryTotals(categoryTotals)}
                                      </TableBody>
                                   </Table>
                                </Grid>
                                {/*<Grid item xs={4}>*/}
                                            {/*{renderCategoryTabs(overviewData.transactionsByCategory)}*/}
                                {/*</Grid>*/}
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default OverviewTable;

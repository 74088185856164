import * as React from 'react';
import {Component, FC, useEffect, useState} from "react";
import {
    DataGrid,
    GridColDef, GridFilterModel, GridPreProcessEditCellProps,
    GridRenderCellParams,
    GridRenderEditCellParams, GridSortDirection, GridSortModel, GridValidRowModel,
    GridValueOptionsParams
} from "@mui/x-data-grid";
import InsightService from "../../services/Insights/Insight.service";
import {Transactions} from "../../contracts/Transactions";
import {ResponseEnum} from "../../Helpers/Enums/ResponseEnum";
import Moment from "moment";
import {Chip, TableCell} from "@mui/material";
import Typography from "@mui/material/Typography";

type InsightsTableProps = {
    // using `interface` is also ok
    message: string;
    transactionsInput: any;
};
type InsightsTableState = {
    count: number; // like this
};



export default function InsightsTable(transactionsInput: any) {

    const [transactions, setTransactions] = useState<any>(transactionsInput.transactions);
    // const [classifyCategories, setClassifyCategories] = useState<string[] | null>(null)

    // const [pageSize, setPageSize] = useState(50);
    // const [page, setPage] = useState(1);

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: "transactionDate",
            sort: "desc" as GridSortDirection,
        },
    ])

    const [filterModel, setFilterModel] = useState<GridFilterModel>();


// Check out below to see custom cell rendering
// https://mui.com/x/react-data-grid/column-definition/#rendering-cells
    const columns: GridColDef[] = [
        {
            field: 'transactionDate',
            headerName: 'Datum',
            type: 'date',
            width: 150,
            renderCell: (params: GridRenderCellParams<Date>) => {

                if(!params || !params.value) {
                    return '';
                }

                return Moment(params.value).format('D MMMM YYYY');
            }
        },
        {
            field: 'beneficiaryName',
            headerName: 'Naam begunstigde',
            width: 350
        },
        {
            field: 'beneficiaryIban',
            headerName: 'IBAN begunstigde',
            width: 200
        },
        {
            field: 'amount',
            type: 'number',
            headerName: 'Bedrag',
            width: 125,
            renderCell: (params: GridRenderCellParams<number>) => {
                if(!params || !params.value) {
                    return '';
                }

                let value = params.value.toLocaleString('nl-NL', {
                    style: 'currency',
                    currency: 'EUR'
                } as Intl.DateTimeFormatOptions);

                if(params.value > 0) {
                    return (
                        <Typography  style={{ color: '#4caf50' }}>
                            {value}
                        </Typography>
                    );
                } else {
                    return (
                        <Typography style={{ color: '#f44336' }}>
                            {value}
                        </Typography>
                    );
                }


                return
            }
        },
        {
            field: 'finalCategory',
            headerName: 'Categorie',
            width: 200,
            editable: false,
            type: 'singleSelect',
            renderCell: (params: GridRenderCellParams<number>) => {
                if(!params || !params.value) {
                    return '';
                }

                // let fontColor = (parseInt(params.row.extendedClassifyCategory.color.replace('#', '') as any, 16) > 0xffffff / 2) ? '#000' : '#fff';

                return  <Chip variant="outlined" label={params.value} style={{borderColor: params.row.extendedClassifyCategory.color}} />

            }
            // valueOptions: (params: GridValueOptionsParams<GridValidRowModel>) => {
            //     if(!classifyCategories) {
            //         return [];
            //     }
            //     return Object.values(classifyCategories) as any; //TODO: Figure out which type this actually needs...
            // },
            // preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            //     const hasError = false;
            //
            //     let id = params.id;
            //     let newCategory = params.props.value;
            //
            //     console.log(newCategory);
            //     TODO: Do an API call here to change the category
                //
                // return { ...params.props, error: hasError };
            // }
        },
        {
            field: 'description',
            headerName: 'Omschrijving',
            width: 550
        },
    ]

    useEffect(() => {

        // const getClassifyCategories = async () => {
            // setClassifyCategories(await InsightService.getClassifyCategories());
        // }

        // getClassifyCategories();

    }, []);

    // This use effect is called during initial rendering and every time page, pageSize, sortModel or filterModel changes
    // useEffect(() => {

        // TODO: add these to the request to filter and sort
        // console.log(sortModel);
        // console.log(filterModel);

        // const getTransactionsWrapper = async () => {
            // setTransactions(await InsightService.getPaginatedTransactions(page, pageSize))
        // }


        // getTransactionsWrapper();


    // }, [page, pageSize, sortModel, filterModel])

    if(!transactions) {
        return (
            <>
                Loading
            </>
        );
    }

    // console.log(transactions);

    return (
        <div style={{height: 900}}>
            <DataGrid
                rows={(transactions)}
                loading={transactions.length === 0}
                // page={(transactions as Transactions).currentPage}
                // pageSize={(transactions as Transactions).perPage}
                rowCount={transactions.length}

                // onPageChange={(newPage) => setPage(newPage)}
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}

                sortModel={sortModel}
                onSortModelChange={(newModel) => setSortModel(newModel)}

                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}

                columns={columns}
                rowsPerPageOptions={[50, 100, 250, 500]}
                pagination
                rowHeight={38}
                paginationMode="client"
                filterMode="client"
                sortingMode="client"
                disableSelectionOnClick
            />
        </div>
    );
}
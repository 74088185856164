import React, {FC, Fragment, useEffect, useState} from 'react';
import styles from './DoughnutLegend.module.scss';
import Header from "../Header/Header";
import {
    Card,
    CardContent, CardHeader, Chip,
    Icon,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {DropzoneArea} from "material-ui-dropzone";
import FileService from "../../services/User/File.service";
import AuthenticationService from "../../services/User/Authentication.service";
import insightService from "../../services/Insights/Insight.service";
import {UserObject} from "../../contracts/Company";
import {CircularProgress, Grid, LinearProgress} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import InsightsTable from "../Insights/InsightsTable";
import {useDashboardStats} from "../../Hooks/useDashboardStats";
import {Doughnut, Line, Pie} from "react-chartjs-2";
import { useLocation } from "react-router-dom"
import {TableRows} from "@mui/icons-material";

interface DoughnutLegendProps {
    data: any
}

const renderRowData = (color: any, label: any, data: any) => {

    const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <div className="legend-item" data-index="5" style={{whiteSpace: "nowrap"}}>
            <span style={{backgroundColor: color}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span className="label" style={{
                width: "95%",
                display: 'inline-block',
                maxWidth: '100%',
                padding: '0 8px',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: 1.2,
                borderRadius: '4px'
            }}>
                {label}
                <span style={{float: 'right'}}>
                    {formatter.format(data)}
                </span>
            </span>
        </div>
    );
}

const DoughnutLegend: FC<DoughnutLegendProps> = (props) => {

    let dataset = props.data.data.datasets[0].data;
    let colors = props.data.data.datasets[0].backgroundColor;
    let labels = props.data.data.labels;

    const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <Table size={"small"}>
            <TableBody>
                {labels.map((item: any, index: any) => {
                    return (
                      <TableRow key={index}>
                          <TableCell>
                            {renderRowData(colors[index], item, dataset[index])}
                          </TableCell>
                      </TableRow>
                    )
                })}
            </TableBody>
        </Table>

    );
};

export default DoughnutLegend;

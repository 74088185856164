import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'typeface-roboto'
import {BrowserRouter} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios, {AxiosRequestConfig} from "axios";

const theme = createTheme({
    palette: {
        primary: {
            main: '#013f63',
            light: '#013f63'
        },
        secondary: {
            main: '#F8F8F8'
        }
    }
});

const ANALYSIS_SERVICE_URL = process.env.REACT_APP_ANALYSIS_SERVICE_URL;

axios.interceptors.request.use((req: AxiosRequestConfig) => {
    if(req.url!.includes(ANALYSIS_SERVICE_URL!)) {
        let user = JSON.parse(localStorage.getItem('user') || JSON.stringify({
            "email": 'demo@gatortech.nl',
            "kvkNumber": '12345678',
            "token": 'adfsa98ds7f98asd7',
            "company": undefined
        }))

        if (user) {
            // req.headers!['Authorization'] = 'Bearer ' + user?.token
            // req.headers!['Email'] = user?.email
            // req.headers!['KvkNumber'] = user?.kvkNumber
        }

        return req
    } else {
        return req
    }
})

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState, useCallback } from 'react';
import transitions from "@material-ui/core/styles/transitions";
import { defaults } from 'react-chartjs-2';

export interface DashboardStatsProps {
    getBalanceData: (transactions: any) => any;
    getTransactionData: (transactions: any) => any;
    getOverviewData: (transactions: any) => any;
    getDebitChartData: (transactions: any) => any;
    getCreditChartData: (transactions: any) => any;
    getInOutPerMonth: (transactions: any) => any;
}

export const useDashboardStats = (): DashboardStatsProps => {

    const getOverviewData = useCallback((transactions: any) => {
        // console.log(transactions);

        let result = rebuildData(transactions);

        let categoryTotals = transactions.classifyData.categoryTotals;
        if('Ontvangen leningen / betalingen aan leningen - Lease' in categoryTotals) {

            if(categoryTotals['Ontvangen leningen / betalingen aan leningen - Lease'].in > 0) {
                categoryTotals['Ontvangen leningen'] = {
                    in: categoryTotals['Ontvangen leningen / betalingen aan leningen - Lease'].in,
                    out: 0,
                };
            }
            if(categoryTotals['Ontvangen leningen / betalingen aan leningen - Lease'].out < 0) {
                categoryTotals['Betalingen aan leningen - Lease'] = {
                    in: 0,
                    out: categoryTotals['Ontvangen leningen / betalingen aan leningen - Lease'].out,

                };
            }

            delete categoryTotals['Ontvangen leningen / betalingen aan leningen - Lease'];
        }

        let overviewData: any= [];
        overviewData['clientInfo'] = transactions.clientInfo;
        overviewData['accountData'] = transactions.classifyData.data;
        overviewData['categoryTotals'] = categoryTotals;
        overviewData['transactionsByCategory'] = transactions.classifyData.transactionsByLoan;


        return overviewData;



    }, []);

    const getInOutPerMonth = useCallback((transactions: any) => {

        let resultRebuild = rebuildData(transactions);
        let result = [...resultRebuild];
        result.sort((a: any, b: any) => a.transactionDateParsed - b.transactionDateParsed);

        const inDataArray = Array.from({length: 12}, (_, i) => i + 1).reduce((acc: any, cur) => {
            acc[cur] = 0;
            return acc;
        }, {});


        const outDataArray = Object.assign({}, inDataArray);
        const netDataArray = Object.assign({}, inDataArray);

        result.map((transaction: any) => {
            let month = transaction.transactionDateParsed.getMonth() + 1;
            if(transaction.amount > 0) {
                inDataArray[month] += transaction.amount;
            } else {
                outDataArray[month] += transaction.amount;
            }
            netDataArray[month] += transaction.amount;
        });


        const labels = Object.keys(inDataArray);
        const inData = Object.values(inDataArray);
        const outData = Object.values(outDataArray);
        const balanceData = Object.values(netDataArray);

        let chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Bijschrijvingen',
                    data: inData,
                    borderColor: 'green',
                    fill: true,
                    backgroundColor: 'rgba(204, 255, 203, 0.4)',
                    pointRadius: 0
                },
                {
                    label: 'Afschrijvingen',
                    data: outData,
                    borderColor: '#FF0000',
                    fill: true,
                    backgroundColor: 'rgba(255, 204, 203, 0.4)',
                    pointRadius: 0
                },
                {
                    label: 'Saldo',
                    data: balanceData,
                    borderColor: 'rgba(0, 0, 255, 1)',
                    pointRadius: 0,
                    fill: false,
                },
            ]
        };


        return {
            data: chartData,
            options: {
                maintainAspectRatio: false,
            },
            plugins: {
                legend: false,
            }
        };

    }, []);


    const getBalanceData = useCallback((transactions: any) => {


        return {
            labels: transactions.classifyData.dataSelectionLabels,
            dataset: transactions.classifyData.dataSelection
        }

        let resultRebuild = rebuildData(transactions);
        let result = [...resultRebuild];
        result.sort((a: any, b: any) => a.transactionDateParsed - b.transactionDateParsed);

        // Get the object with the lowest date
        let firstTransaction = result[0];
        // Get the object with the highest date
        let lastTransaction = result[result.length - 1];

        let dates = getDatesArray(firstTransaction.transactionDateParsed, lastTransaction.transactionDateParsed)

        const datesObject = dates.reduce((acc: any, curr: string) => {
                acc[curr] = 0;
                return acc;
        }, {});

        let lastBalance = 0;

        for (const date in datesObject) {
            if (datesObject.hasOwnProperty(date)) {
                const balanceObjects = result.filter((obj: any) => obj.transactionDateParsed.toISOString().substring(0, 10) === date);

                if (balanceObjects.length > 0) {
                    lastBalance = balanceObjects.pop().endBalance;
                }

                datesObject[date] = lastBalance;
            }
        }


        const labels = Object.keys(datesObject);
        const data = Object.values(datesObject);

        let chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Saldo over tijd',
                    data: data,
                    borderColor: 'blue',
                    pointRadius: 0


                }
            ]
        };


        return {
            data: chartData,
            options: {
                maintainAspectRatio: false,
                tooltips: {
                    position: 'nearest'
                },
                scales: {
                    y: {
                        beginAtZero: false
                    }
                }
            }
        };

        }, []);

    const getDebitChartData = useCallback((transactions: any) => {
        let result = rebuildData(transactions);

        let dataByCategory: any[] = [];

        let labelsAndColors: any[] = [];

        transactions.categories.map((item: any) => {
            labelsAndColors[item.name] = item.color;
        });

        labelsAndColors['' as any] = '#ececec';


        result.map((transaction: any) => {

            if(transaction.amount > 0) {
                return;
            }

            if(!dataByCategory[transaction.finalCategory]) {
                dataByCategory[transaction.finalCategory] = 0;
            }

            dataByCategory[transaction.finalCategory] += transaction.amount;

            if(transaction.finalCategory) {
                labelsAndColors[transaction.finalCategory] = transaction.extendedClassifyCategory.color;
            } else {
                labelsAndColors[transaction.finalCategory] = '#ececec';
            }

        });
        (dataByCategory as any) = Object.entries(dataByCategory)
            .sort(([,a],[,b]) => a-b)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});


        const categories = Object.keys(dataByCategory);
        const colors = categories.map((item: any) => labelsAndColors[item] );
        const data = categories.map((item: any) => dataByCategory[item]);

        let chartData = {
            labels: categories,
            datasets: [
                {
                    label: 'Afschrijvingen per categorie',
                    data: data,
                    backgroundColor: colors,

                }
            ]
        };


        return {
            data: chartData,
            options: {
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true
                },
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            // generateLabels: (chart: any) => {
                            //     const {data} = chart;
                            //     const legendItems = data.datasets.map((dataset: any, index: any) => {
                                    // const backgroundColor = dataset.backgroundColor || defaults.color(index);
                                    // const borderColor = dataset.borderColor || defaults.borderColor;
                                    // const borderWidth = dataset.borderWidth || defaults.borderWidth;
                                    // const pointStyle = dataset.pointStyle || defaults.pointStyle;
                                    //
                                    //
                                    //
                                    // return {
                                    //     text: dataset.label,
                                    //     fillStyle: backgroundColor,
                                    //     hidden: !chart.data.datasets[index].visible,
                                    //     index,
                                    //     datasetIndex: index,
                                    //     borderColor,
                                    //     borderWidth,
                                    //     pointStyle,
                                    // };
                                // });
                                // return legendItems;
                            // },
                        }
                    }
                }
            },
        };


    }, []);

    const getCreditChartData = useCallback((transactions: any) => {
        let result = rebuildData(transactions);

        let dataByCategory: any[] = [];

        let labelsAndColors: any[] = [];

        transactions.categories.map((item: any) => {
            labelsAndColors[item.name] = item.color;
        });

        labelsAndColors['' as any] = '#ececec';

        result.map((transaction: any) => {

            if(transaction.amount < 0) {
                return;
            }

            if(!dataByCategory[transaction.finalCategory]) {
                dataByCategory[transaction.finalCategory] = 0;
            }

            dataByCategory[transaction.finalCategory] += transaction.amount;

            if(transaction.finalCategory) {
                labelsAndColors[transaction.finalCategory] = transaction.extendedClassifyCategory.color;
            } else {
                labelsAndColors[transaction.finalCategory] = '#ececec';
            }

        });
        (dataByCategory as any) = Object.entries(dataByCategory)
            .sort(([,a],[,b]) => b-a)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

        // console.log(dataByCategory);

        const categories = Object.keys(dataByCategory);
        const colors = categories.map((item: any) => labelsAndColors[item] );
        const data = categories.map((item: any) => dataByCategory[item]);

        let chartData = {
            labels: categories,
            datasets: [
                {
                    label: 'Bijschrijvingen per categorie',
                    data: data,
                    backgroundColor: colors,
                    legend: {
                        // enabled: true,
                        // labels: [],
                        // display: false
                    },
                }
            ]
        };


        return {
            data: chartData,
            options: {
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };

    }, []);

    const getTransactionData = useCallback((transactions: any) => {

        let result = rebuildData(transactions);


        return result;
    }, []);

    const rebuildData = useCallback((transactions: any) => {

        //let result = transactions.accounts[0].transactions;
        let result = transactions.transactions;
        result.map((transaction: any) => {

            transaction.transactionDateParsed = new Date(Date.parse(transaction.transactionDate));

            transaction.endBalance = Math.round((transaction.endBalance + Number.EPSILON) * 100) / 100;

            transaction.finalCategory = '';//transaction.classifyCategory;
            if(transaction.extendedClassifyCategory !== null && typeof transaction.extendedClassifyCategory === 'object') {
                transaction.finalCategory = transaction.extendedClassifyCategory.name;
            }

            if(transaction.finalCategory === '') {
                transaction.finalCategory = 'Zonder categorie';
                transaction.extendedClassifyCategory = {
                    code: 'no-cateogry',
                    name: 'Zonder categorie',
                    color: '#ececec'
                }
            }

            if(transaction.finalCategory === 'Ontvangen leningen / betalingen aan leningen - Lease') {

                if(transaction.amount > 0) {
                    transaction.finalCategory = 'Ontvangen leningen';
                    transaction.extendedClassifyCategory.name = 'Ontvangen leningen';
                    transaction.extendedClassifyCategory.code = 'loan-credit';
                } else {
                    transaction.finalCategory = 'Betalingen aan leningen - Lease';
                    transaction.extendedClassifyCategory.name = 'Betalingen aan leningen - Lease';
                    transaction.extendedClassifyCategory.code = 'loan-debit';
                }
            }
            transaction.beneficiaryName = '';
            transaction.beneficiaryIban = '';

            if(typeof transaction.beneficiary === 'object') {
                transaction.beneficiaryName = transaction.beneficiary.name;
                transaction.beneficiaryIban = transaction.beneficiary.iban;
            }
        });

        return result;
    }, []);



    const getDatesArray = useCallback((startDate, endDate) => {
        const datesArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            datesArray.push(currentDate.toISOString().substring(0, 10));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return datesArray;
    }, []);


    return {
        getBalanceData,
        getTransactionData,
        getOverviewData,
        getDebitChartData,
        getCreditChartData,
        getInOutPerMonth,
    };
};





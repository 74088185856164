import React, {FC, useEffect, useState} from 'react';
import styles from './Upload.module.scss';
import Header from "../Header/Header";
import {
    Card,
    CardContent,
    Icon,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {DropzoneArea} from "material-ui-dropzone";
import FileService from "../../services/User/File.service";
import AuthenticationService from "../../services/User/Authentication.service";
import insightService from "../../services/Insights/Insight.service";
import {UserObject} from "../../contracts/Company";
import {LinearProgress} from "@material-ui/core";
import {useNavigate} from "react-router-dom";

interface UploadProps {}

const Upload: FC<UploadProps> = () => {
    const [filesToUpload, setFilesToUpload] = useState<File[] | null>(null)
    const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false)
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [user, setUser] = useState<UserObject | null>()

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('mt940_result');
    }, []);

    useEffect(() => {
        let user = AuthenticationService.getCurrentUser();
        setUser(user)
        //setAddress(user?.company._EmbeddedCompanyInfo.headquarters.address[0])
    }, [uploadSuccessful])

    useEffect(() => {

        // console.log('fixed files uploaded');
        // console.log(filesToUpload);
        if(filesToUpload === null) {
            return;
        }
        setIsUploading(true);

        const fetchData = async () => {
            await handleUpload();
        }

        fetchData()
            // make sure to catch any error
            .catch(console.error);
        //await handleUpload();

    }, [filesToUpload]);

    const handleUpload = async () => {

        if (filesToUpload && filesToUpload.length > 0) {
            //var result = await FileService.getDashboardStats(filesToUpload);
            let content = atob(await filesToUpload[0].text() as any);

            (window as any).classify_result = content;

            // localStorage.setItem('classify_result', content);
            if (content) {
                setUploadSuccessful(true);
                setFilesToUpload([])
            }

            navigate('/dashboard');
        }
    }

    const prepareFiles = async (files: File[]) => {
        let uniqueFiles: File[] = []

        files.forEach((file) => {
            if (!uniqueFiles) {
                uniqueFiles = [file]
            } else {
                let isUnique = true

                uniqueFiles.forEach((uniqueItem) => {
                    if (uniqueItem.name === file.name) isUnique = false
                })

                if (isUnique) {
                    uniqueFiles = [...uniqueFiles, file]
                }
            }
        })

        if (uniqueFiles && uniqueFiles.length > 0) {
            if (!filesToUpload) {
                setFilesToUpload(uniqueFiles)
            } else {
                uniqueFiles.forEach(async (file) => {
                    await filesToUpload.forEach((fileObj: File) => {
                        if (fileObj.name !== file.name) {
                            setFilesToUpload([...filesToUpload, file])
                        }
                    })
                })
            }
        }

        if (filesToUpload && filesToUpload.length === 0) {
            setFilesToUpload(null)
        }
    }


    const deleteFile = (file: File) => {
        const resultingList = filesToUpload!.filter(existingFile => (file.name !== existingFile.name))

        if (resultingList.length === 0) {
            setFilesToUpload(null)
        } else {
            setFilesToUpload(resultingList)
        }
    }

    // console.log(isUploading);

    return (
        <div className={styles.Upload}>
        <Header></Header>
        <div className={styles.container}>
            <Card sx={{
                width: 'calc((100%) - 30px)',
                padding: '15px'
            }}>
                <CardContent>
                    <div>
                        <span className={styles.uploadTitle}>Bankexport uploaden</span>
                    </div>
                    <DropzoneArea dropzoneClass={styles.dropzone}
                                  dropzoneText="Drag en drop of klik om te uploaden (Classify Bestand)"
                                  onChange={(files) => prepareFiles(files)}
                                  showPreviews={false}
                                  acceptedFiles={['.classify']}
                                  showFileNames={true}
                                  maxFileSize={30000000}
                                  showPreviewsInDropzone={true}
                                  showFileNamesInPreview={false}
                                  filesLimit={1}

                    >
                    </DropzoneArea>

                    {/*{filesToUpload && filesToUpload.length > 0 && (*/}
                    {/*    <div>*/}
                    {/*        <TableContainer component={Paper} sx={{margin: '20px 0'}}>*/}
                    {/*            <Table aria-label="simple table">*/}
                    {/*                <TableHead>*/}
                    {/*                    <TableRow>*/}
                    {/*                        <TableCell>Toegevoegde bestanden</TableCell>*/}
                    {/*                        <TableCell></TableCell>*/}
                    {/*                    </TableRow>*/}
                    {/*                </TableHead>*/}
                    {/*                <TableBody>*/}
                    {/*                    {filesToUpload.map((file, index) => (*/}
                    {/*                        <TableRow*/}
                    {/*                            key={index}*/}
                    {/*                            sx={{'&:last-child td, &:last-child th': {border: 0}}}*/}
                    {/*                        >*/}
                    {/*                            <TableCell component="th" scope="row">{file.name}</TableCell>*/}
                    {/*                            <TableCell component="th" scope="row"><Icon*/}
                    {/*                                className={styles.deleteFileIcon} onClick={() => {*/}
                    {/*                                deleteFile(file)*/}
                    {/*                            }}>delete</Icon></TableCell>*/}
                    {/*                        </TableRow>*/}
                    {/*                    ))}*/}
                    {/*                </TableBody>*/}
                    {/*            </Table>*/}
                    {/*        </TableContainer>*/}

                    {/*        <div className={styles.submitFilesButton} onClick={async () => {*/}
                    {/*            await handleUpload()*/}
                    {/*        }}>Uploaden*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </CardContent>
            </Card>
            {isUploading && (
                <Card sx={{
                    width: 'calc((100%) - 30px)',
                    padding: '15px'
                }}>
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                </Card>
            )}
        </div>
    </div>
);
};

export default Upload;

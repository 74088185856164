import axios from 'axios';

const API_URL = process.env.REACT_APP_ANALYSIS_SERVICE_URL;

class FileService {
    async uploadTransactionsFile(files: File[]) {
        let formData = new FormData();

        files.map(f => {
            formData.append("files", f)
        })

        return await axios.post(API_URL + "financialtransactions/upload", formData)
            .then((r) => {
                return r.status.toString().startsWith('2')
             });
    }

    async getDashboardStats(files: File[]) {
        let formData = new FormData();

        files.map(f => {
            formData.append("files", f)
        })

        return await axios.post(API_URL + "crm/classify/mt940/get-stats", formData)
            .then((response) => {
                if (response.status.toString().startsWith('2')) {
                    return response.data
                }
             });
    }
    async getBeoordelingDownload(encoded: string) {

        return await axios.post(API_URL + "crm/opportunity/beoordeling/download/" + encoded, )
            .then((response) => {
                if (response.status.toString().startsWith('2')) {
                    return response.data
                }
             });
    }
}

export default new FileService();

import React, {FC, useContext, useEffect, useState} from 'react';
import styles from './Dashboard.module.scss';
import Header from "../Header/Header";
import {
    Card,
    CardContent, CardHeader, Chip,
    Icon,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {DropzoneArea} from "material-ui-dropzone";
import FileService from "../../services/User/File.service";
import AuthenticationService from "../../services/User/Authentication.service";
import insightService from "../../services/Insights/Insight.service";
import {UserObject} from "../../contracts/Company";
import {CircularProgress, Grid, LinearProgress} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import InsightsTable from "../Insights/InsightsTable";
import {useDashboardStats} from "../../Hooks/useDashboardStats";
import {Chart, Doughnut, Line, Pie} from "react-chartjs-2";
import { useLocation } from "react-router-dom"
import OverviewTable from "./OverviewTable";
// @ts-ignore
import { Parser as HtmlToReactParser } from 'html-to-react';
import BalanceChart from "./BalanceChart";
import { useUserInformation } from "../../Contexts";
import DoughnutLegend from './DoughnutLegend';

const htmlToReactParser = new HtmlToReactParser();

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {

    const user: any = useUserInformation();

    const [data, setData] = useState<any>()
    // const [hasRef, setHasRef] = useState<any>()
    const [overviewData, setOverviewData] = useState<any>()
    const [transactionData, setTransactionData] = useState<any>()
    const [debitChartData, setDebitChartData] = useState<any>()
    const [creditChartData, setCreditChartData] = useState<any>()
    const [balanceChartData, setBalanceChartData] = useState<any>()
    const [inOutPerMonth, setInOutPerMonth] = useState<any>()

    // const chartRef = useRef<any>(null);

    const location = useLocation();
    const params = new URLSearchParams(location.search);


    const navigate = useNavigate();

    let provider = useDashboardStats();


    useEffect(() => {

        // let data = JSON.parse(localStorage.getItem('classify_result') as any);

        if(data == null) {
            return;
        }

        setTransactionData(provider.getTransactionData(data));
        setOverviewData(provider.getOverviewData(data));
        setDebitChartData(provider.getDebitChartData(data));
        setCreditChartData(provider.getCreditChartData(data));
        setBalanceChartData(provider.getBalanceData(data));
        setInOutPerMonth(provider.getInOutPerMonth(data));

        // console.log(data);
        user.setUser((data.clientInfo as any));

    }, [data]);

    useEffect(() => {

        let encoded = params.get('encoded');
        // let classifyData = localStorage.getItem('classify_result');

        if (encoded === null && (window as any).classify_result == null) {
            navigate('/');
            return;
        }

        // console.log(typeof((window as any).classify_result));

        if(typeof((window as any).classify_result) === 'string') {
            setData(JSON.parse((window as any).classify_result));
            return;
        }

        // if (encoded === localStorage.getItem('encoded')) {
        //
        //     let classifyData = localStorage.getItem('classify_result');
        //
        //     let parsed = JSON.parse(classifyData as any);
        //
        //     setData(parsed);
        //     return;
        // }

        const getTransctions = async (encoded: string) => {

            var result = await FileService.getBeoordelingDownload(encoded);

            // localStorage.setItem('encoded', encoded)
            // localStorage.setItem('classify_result', JSON.stringify(result));
            setData(result);
        }

        getTransctions(encoded as string)
            .catch(console.error);

    }, [])


    // useEffect(() => {
    //     const chart = chartRef.current;
    //
    //     console.log(chart);
    //     if(chart != null) {
    //         setHasRef(true);
    //     }
    // });


    // useEffect(() => {
    //     if (chartRef.current) {
    //         console.log()
    //         htmlToReactParser.parse((chartRef.current as any).chartInstance.generateLegend());
    //     }
    // }, [hasRef]);
    //
    // console.log(chartRef.current);

    return (
        <div className={styles.wrapper}>
        <Header></Header>
        <div className={styles.container}>

            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <Card sx={{
                        width: 'calc((100%) - 30px)',
                        padding: '15px'
                    }}>
                        <CardHeader titleTypographyProps={{
                            fontWeight: 800,
                        }} title={"Saldo over tijd"} style={{color: '#013f63', fontWeight: 'bold'}} />
                        <CardContent>
                            {balanceChartData ? (
                                <BalanceChart balanceData={balanceChartData} />
                                // <Line height={350} data={balanceChartData.data} options={balanceChartData.options} plugins={balanceChartData.plugins}/>
                            ): (
                                <CircularProgress />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>

                            {overviewData ?
                                <OverviewTable overviewData={overviewData} />
                                : (

                                    <Card sx={{
                                        width: 'calc((100%) - 30px)',
                                        padding: '15px'
                                    }}>
                                        <CardHeader titleTypographyProps={{
                                            fontWeight: 800,
                                        }} title={"Overzicht van uw gegevens"} style={{olor: '#013f63', fontWeight: 'bold'}} />
                                        <CardContent>
                                            <CircularProgress />
                                        </CardContent>
                                    </Card>
                                )}
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{
                        width: 'calc((100%) - 30px)',
                        padding: '15px'
                    }}>
                        <CardHeader titleTypographyProps={{
                            fontWeight: 800,
                        }} title={"Bij/Af per maand"} style={{color: '#013f63', fontWeight: 'bold'}} />
                        <CardContent>
                            {inOutPerMonth ? (
                                <Line height={250} data={inOutPerMonth.data} options={inOutPerMonth.options} plugins={inOutPerMonth.plugins}/>
                            ): (
                                <CircularProgress />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{
                        width: 'calc((100%) - 30px)',
                        padding: '15px',
                        height: 'calc((100%) - 30px)'
                    }}>
                        <CardHeader titleTypographyProps={{
                            fontWeight: 800,
                        }} title={"Bijschrijvingen per categorie"} style={{color: '#013f63', fontWeight: 'bold'}} />
                        <CardContent>
                            {creditChartData ? (
                                <>
                                    <div>
                                        <Doughnut height={250}  data={creditChartData.data} options={creditChartData.options} plugins={creditChartData.plugins}/>
                                    </div>
                                    <div>
                                        <DoughnutLegend data={creditChartData} />
                                    </div>
                                </>
                            ): (
                                <CircularProgress />
                            )}
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={4}>
                    <Card sx={{
                        width: 'calc((100%) - 30px)',
                        padding: '15px',
                        height: 'calc((100%) - 30px)'
                    }}>
                        <CardHeader titleTypographyProps={{
                            fontWeight: 800,
                        }} title={"Afschrijvingen per categorie"}  style={{color: '#013f63', fontWeight: 'bold'}} />
                        <CardContent>
                            {debitChartData ? (
                                <>
                                    <div>
                                        <Doughnut  height={250} data={debitChartData.data} options={debitChartData.options}  />
                                    </div>
                                    <div>
                                        <DoughnutLegend data={debitChartData} />
                                    </div>

                                </>
                                ): (
                                <CircularProgress />
                            )}
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12}>
                    <Card sx={{
                        width: 'calc((100%) - 30px)',
                        padding: '15px'
                    }}>
                        <CardHeader titleTypographyProps={{
                            fontWeight: 800,
                        }} title={"Transacties"} style={{color: '#013f63', fontWeight: 'bold'}} />
                        <CardContent>

                            {transactionData ? (
                                <TableContainer component={Paper} sx={{margin: '0'}}>
                                    <InsightsTable transactions={transactionData} />
                                </TableContainer>
                            ) : (
                                <CircularProgress />
                            )}


                        </CardContent>
                    </Card>
                </Grid>

            </Grid>

        </div>
    </div>
);
};

export default Dashboard;

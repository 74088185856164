import React, {FC} from 'react';
import styles from './UnderConstruction.module.scss';
import Header from "../Header/Header";
import {Grid} from "@material-ui/core";
import {Card, CardContent, Icon, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";

interface UploadProps {}

const UnderConstruction: FC<UploadProps> = () => {

    const theme = useTheme();

    return (
        <div className={styles.Upload}>
        <Header></Header>
        <div className={styles.container}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} lg={12}>

                    <Typography align="center" variant="h3" className={styles.comingSoon}>
                        <Icon fontSize={"large"}  style={{marginRight: '10px', color: theme.palette.primary.main}}>construction</Icon>
                        Coming soon
                    </Typography>
                </Grid>
            </Grid>
        </div>
    </div>
);
};

export default UnderConstruction;

import axios from "axios";
import {Company, UserObject} from "../../contracts/Company";

let Buffer = require('buffer/');

const API_URL = process.env.REACT_APP_USER_SERVICE_URL;

class AuthenticationService {
    private refreshTokenTimeout: any

    async authenticate(username: string|undefined, password: string|undefined) {
        return await axios
            .post<UserObject>(API_URL + "user/authenticate", {
                "email": username,
                "password": password
            }, { withCredentials: true })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.status.toString().startsWith('2');
            });
    }

    destroySession() {
        localStorage.removeItem("user");
    }

    async logout(token: string) {
        await axios.post(API_URL + "user/logout", {
            token
        });

        this.destroySession();
    }

    getCurrentUser() {

        let user: UserObject;
        user = {
            "email": 'demo@gatortech.nl',
            "kvkNumber": '12345678',
            "token": 'adfsa98ds7f98asd7',
            "company": undefined
        };

        localStorage.setItem("user", JSON.stringify(user));

        return user;
        // const userStr = localStorage.getItem("user");

        // if (userStr) return JSON.parse(userStr) as UserObject;

        // return null;
    }

    isAuthenticated() {

        return 'demo';

        const queryParams = new URLSearchParams(window.location.search);
        let token = queryParams.get('token') as string;
        let tokenString = atob(token);

        if(token !== undefined && token !== null && token.length > 0) {
            localStorage.setItem('originalToken', token);

            localStorage.setItem("user", JSON.stringify({
                "email": tokenString.split(':')[1],
            }));
        }

        return (localStorage.getItem('user') || '').length > 0
    }

    refreshToken = () => {
        return axios.post<any>(`API_URL`, {}, { withCredentials: true })
            .then((r) => {
                this.startRefreshTokenTimer(r.toString());
                return r;
            })
    }

    private startRefreshTokenTimer(token: string): void {
        // Parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(token.split('.')[1]);

        // Set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
    }
}

export default new AuthenticationService();

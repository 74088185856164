import React, {FC, useEffect} from 'react';
import {Icon, useTheme} from "@mui/material";
import styles from './SideMenuItem.module.scss';
import {useLocation} from "react-router-dom";

interface SideMenuItemProps {
    icon: string,
    linkToUrl: string,
    itemText: string
    itemTextIcon?: string
}

const SideMenuItem: FC<SideMenuItemProps> = (props) => {
    const theme = useTheme();
    const [isActive, setIsActive] = React.useState(false)
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === props.linkToUrl) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [])

    return <>
        <div className={styles.SideMenuItem}>
            <Icon fontSize={"large"} style={isActive ? {color: theme.palette.primary.main} : {color: '#C1C1C1'}}>{props.icon}</Icon>
            <a href={props.linkToUrl}>
                {props.itemText}
            </a>
            {props.itemTextIcon ?
                <Icon fontSize={"small"} style={isActive ? {color: theme.palette.primary.main} : {color: '#C1C1C1'}}>{props.itemTextIcon}</Icon>
                : '' }
        </div>
    </>
};

export default SideMenuItem;

import React, {FC, Fragment, useEffect, useState} from 'react';
import {
    TableCell,
    TableRow
} from "@mui/material";
import ReactECharts from 'echarts-for-react';

interface BalanceChartProps {
    balanceData: any
}


const renderCategoryTotals = (categoryTotals: any): any => {

    let counter = 0;

    return Object.entries(categoryTotals).map((item: any) => {

        const [key, value] =  item;

        // console.log(key);
        // console.log(value);

        counter++;

        let saldo = value.in + value.out;

        const formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return (
            <TableRow key={counter}>
                <TableCell style={{ color: 'rgb(78 78 78 / 87%)'}}>{key}</TableCell>
                <TableCell style={{ color: '#4caf50' }}>{value.in !== 0 ? formatter.format(value.in) : '-'}</TableCell>
                <TableCell style={{ color: '#f44336' }}>{value.out !== 0 ? formatter.format(value.out) : '-'}</TableCell>
                <TableCell style={{ color: '#2196F3' }}>{saldo !== 0 ? formatter.format(saldo) : '-'}</TableCell>
            </TableRow>
        )
    });
}

const BalanceChart: FC<BalanceChartProps> = (props) => {

    // let overviewData = props.overviewData;
    //
    // // console.log(overviewData);
    //
    // let accountData = overviewData.accountData;
    // let accounts = accountData.accountsRevised;
    // let categoryTotals = overviewData.categoryTotals;
    // // console.log(accounts);
    //
    // const formatter = new Intl.NumberFormat('nl-NL', {
    //     style: 'currency',
    //     currency: 'EUR',
    //
    //     // These options are needed to round to whole numbers if that's what you want.
    //     //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //     //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    // });

    let {labels, dataset} = props.balanceData;

    var option = {
        title: {
            show: false,
        //     text: 'Balans over tijd'
        },
        xAxis: {
            // type: 'time',
            data: labels
            // ts.map(function (item) {
            //     console.log(item);
            //     return (item[0]);
            // }),
        },
        yAxis: {
        },
        tooltip: {
            trigger: 'axis'
        },
        dataZoom: [{
            // startValue: $('input[name="inversStartDate"]').val(),
            // endValue: inversEndDate
        }, {
            type: 'inside'
        }],
        visualMap: {
            top: 50,
            right: 10,
            pieces: [
                {
                    min: 0.01,
                    max: 9000000,
                    label: 'Positief',
                    color: '#93CE07'
                },
                {
                    max: -0.01,
                    min: -9000000,
                    label: 'Negatief',
                    color: '#FD0100'
                }
            ],
            outOfRange: {
                color: '#999'
            }
        },
        series: {
            name: 'Balans over tijd',
            data: dataset[0].data.map(function (item: any) {
                // console.log(item);
                return parseFloat(item).toFixed(2);
            }),
            type: 'line',
            markLine: {
                silent: true,
                lineStyle: {
                    color: '#333'
                },
                data: [{
                    yAxis: 0
                }]
            }
        }
    };


    return (
        <ReactECharts
            option={option}
            // option={this.getOption()}
            notMerge={true}
            lazyUpdate={true}
            // theme={"theme_name"}
            // onChartReady={this.onChartReadyCallback}
            // onEvents={EventsDict}
            // opts={}
        />
    );
};

export default BalanceChart;

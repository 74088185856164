import React, {createContext, useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import './App.scss';
import Login from "./components/Login/Login";
import Profile from "./components/Profile/Profile";
import Insights from "./components/Insights/Insights";
import CompanyIdentification from "./components/CompanyIdentification/CompanyIdentification";
import Upload from "./components/Upload/Upload";
import Dashboard from "./components/Dashboard/Dashboard";
import {RequireAuth} from "./Helpers/RequireAuth.helper";
import AuthenticationService from "./services/User/Authentication.service";
import SideMenu from "./components/SideMenu/SideMenu";
import {useTheme} from "@mui/material";
import UnderConstruction from "./components/UnderConstruction/UnderConstruction";
import { UserContextProvider } from './Contexts';


function App() {

    const [user, setUser] = useState(null);

    const [authenticated, setAuthenticated] = React.useState<boolean | string>('unknown')
    const theme = useTheme();
    const onLogin = (isAuthenticated: boolean) => {
        setAuthenticated(isAuthenticated)
    }

    useEffect(() => {
        // const isAuth = AuthenticationService.isAuthenticated()

        setAuthenticated(true);
    }, [])

    return (
        <UserContextProvider>
            <div className="App" style={{backgroundColor: theme.palette.secondary.main}}>
                {authenticated !== 'unknown' && authenticated === true && (
                    <div className="sideMenuWrapper">
                        <SideMenu></SideMenu>
                    </div>
                )}
                <div className="appContentWrapper">
                    <div className="appContent">
                        <Routes>
                            <Route path="/" element={<Upload/>}/>
                            <Route path="/dashboard" element={<Dashboard/>}/>
                            {/*<Route path="/" element={<Login onLogin={onLogin}/>}/>*/}
                            <Route path="/profiel" element={<UnderConstruction/>}/>
                            <Route path="/inzichten" element={<UnderConstruction/>}/>
                            <Route path="/vergelijken" element={<UnderConstruction/>}/>
                            <Route path="/trends" element={<UnderConstruction/>}/>
                            {/*<Route path="/company-identification/:accountids" element={<RequireAuth><CompanyIdentification/></RequireAuth>}/>*/}

                            {/*<Route path="/profiel" element={<RequireAuth><Profile/></RequireAuth>}/>*/}
                            {/*<Route path="/inzichten" element={<RequireAuth><Insights/></RequireAuth>}/>*/}
                            {/*<Route path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>}/>*/}
                            {/*<Route path="/company-identification/:accountids" element={<RequireAuth><CompanyIdentification/></RequireAuth>}/>*/}
                        </Routes>
                    </div>
                </div>
            </div>
        </UserContextProvider>
    );
}

export default App;

import React, {FC, useEffect, useState} from 'react';
import styles from './Header.module.scss';
import {UserObject} from "../../contracts/Company";

interface HeaderProps {
}

const Header: FC<HeaderProps> = () => {
    const [userLetters, setUserLetters] = useState<string>();

    useEffect(() => {
        if(localStorage.getItem('user')) {
            setUserLetters((JSON.parse(localStorage.getItem('user') || '') as UserObject).email.substring(0, 2))
        }
    }, [])

    return (
        <div className={styles.header}>
            <img src="/images/logo/eye.png" alt="logo"/>
            <img src="/images/logo/logo_full.png" alt="logo"/>
        </div>
    );
}

export default Header;
